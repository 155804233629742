export var TenantDataBoundary;
(function (TenantDataBoundary) {
    TenantDataBoundary["UNKNOWN"] = "UNKNOWN";
    // Tenants not from EMEA regions
    TenantDataBoundary["GLOBAL"] = "GLOBAL";
    // Tenants from EMEA regions.
    // Note: As part of E+D EUDB Telemetry stream, the collective decision is to send the data from EMEA region to to EU Telemetry stores
    // Therefore, for telemetry stream, we utilize the ECS EUDB-Tenants group, which contains the EMEA tenants only.
    TenantDataBoundary["EMEA"] = "EMEA";
    // Tenants from EU regions (not used at the moment, only added for clarity).
    TenantDataBoundary["EU"] = "EU";
})(TenantDataBoundary || (TenantDataBoundary = {}));
