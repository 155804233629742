import { authService } from "../auth";
import { TenantDataBoundary, TenantDataBoundaryService, } from "../tenant-data-boundary";
export var TelemetryInitializer = function (item) {
    var userAccount = authService.getActiveAccount();
    var dataBoundaryForTelemetry = TenantDataBoundaryService.getTenantDataBoundary();
    if (!item.data) {
        item.data = {};
    }
    item.data.Environment = process.env.ENVIRONMENT || "";
    item.data.Experience =
        window.LTI_LAUNCH_ERROR_CODE === "" ? "baseApp" : "ltiLauchError";
    item.data.TenantId =
        userAccount && userAccount.tenantId ? userAccount.tenantId : "";
    if (process.env.IS_EUDB_TELEMETRY_ROUTING_ENABLED === "true") {
        item.data.AzureSubjectId =
            userAccount &&
                userAccount.localAccountId &&
                dataBoundaryForTelemetry !== TenantDataBoundary.UNKNOWN
                ? userAccount.localAccountId
                : "";
    }
    else {
        item.data.AzureSubjectId =
            userAccount && userAccount.localAccountId
                ? userAccount.localAccountId
                : "";
    }
};
export var GlobalOneDSConfiguration = {
    instrumentationKey: process.env.ARIA_INSTRUMENTATION_KEY,
    extensions: [],
    extensionConfig: [],
    channelConfiguration: {
    // Post channel configuration
    // TODO: valueSanitizer: as per need
    },
    propertyConfiguration: {
        // Properties Plugin configuration
        userAgent: "Custom User Agent",
    },
    webAnalyticsConfiguration: {
        // TODO: If the host site employs MSA or AAD for user authentication and wants Web Analytics to capture the respective user ID this flag needs to be set to true, to allow the site to share its authentication status with OneCollector.
        // Uncomment once auth in place
        // shareAuthStatus: true,
        autoCapture: {
            scroll: true,
            pageView: true,
            onLoad: true,
            onUnload: true,
            click: true,
            resize: true,
            jsError: true, // TODO: Check if this needs sanitization
        },
    },
};
export var EmeaOneDSConfiguration = {
    instrumentationKey: process.env.ARIA_INSTRUMENTATION_KEY,
    extensions: [],
    extensionConfig: [],
    propertyConfiguration: {
        // Properties Plugin configuration
        userAgent: "Custom User Agent",
    },
    webAnalyticsConfiguration: {
        // TODO: If the host site employs MSA or AAD for user authentication and wants Web Analytics to capture the respective user ID this flag needs to be set to true, to allow the site to share its authentication status with OneCollector.
        // Uncomment once auth in place
        // shareAuthStatus: true,
        autoCapture: {
            scroll: true,
            pageView: true,
            onLoad: true,
            onUnload: true,
            click: true,
            resize: true,
            jsError: true, // TODO: Check if this needs sanitization
        },
    },
    channelConfiguration: {
        overrideEndpointUrl: "https://eu-mobile.events.data.microsoft.com/OneCollector/1.0/",
    },
};
