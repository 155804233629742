export var ErrorCode;
(function (ErrorCode) {
    //HTTP errors
    ErrorCode["NetworkOffline"] = "NetworkOffline";
    ErrorCode["Unknown"] = "Unknown";
    ErrorCode["DeserializationFailed"] = "DeserializationFailed";
    ErrorCode["FailedHttpStatus"] = "FailedHttpStatus";
    // Client side errors
    ErrorCode["ContextProviderMissing"] = "ContextProviderMissing";
    ErrorCode["LazyLoadFailure"] = "LazyLoadFailure";
    ErrorCode["ReactErrorBoundary"] = "ReactErrorBoundary";
    ErrorCode["I18nInitFailure"] = "I18nInitFailure";
    //Auth errors from msal
    ErrorCode["AuthErrorInteractiveLoginFailed"] = "AuthErrorInteractiveLoginFailed";
    ErrorCode["AuthErrorSilentSSO"] = "AuthErrorSilentSSO";
    ErrorCode["AuthErrorAcquireTokenSilently"] = "AuthErrorAcquireTokenSilently";
    ErrorCode["AuthErrorInteractionRequired"] = "AuthErrorInteractionRequired";
    ErrorCode["AuthErrorLogoutFailed"] = "AuthErrorLogoutFailed";
    // Authorization error codes recieved from backend
    ErrorCode["TokenNotFound"] = "TokenNotFound";
    ErrorCode["TokenInvalid"] = "TokenInvalid";
    ErrorCode["InteractionRequired"] = "InteractionRequired";
    ErrorCode["Unauthorized"] = "Unauthorized";
    ErrorCode["TenantNotAuthorizedError"] = "TenantNotAuthorizedError";
    // Other backend error codes
    ErrorCode["InternalError"] = "InternalError";
    ErrorCode["BadRequest"] = "BadRequest";
    ErrorCode["Forbidden"] = "Forbidden";
    ErrorCode["NotFound"] = "NotFound";
    ErrorCode["TooManyRequests"] = "TooManyRequests";
    ErrorCode["LtiConfigNotFound"] = "LtiConfigNotFound";
    ErrorCode["LtiAppNotSupported"] = "LtiAppNotSupported";
    ErrorCode["LmsPlatformNotSupported"] = "LmsPlatformNotSupported";
    ErrorCode["CollectionCreationInProgress"] = "CollectionCreationInProgress";
    ErrorCode["SubstrateServiceThrottled"] = "SubstrateServiceThrottled";
    ErrorCode["SubstrateTokenProviderError"] = "SubstrateTokenProviderError";
    ErrorCode["InvalidLtiConfig"] = "InvalidLtiConfig";
    ErrorCode["SubstrateCircuitBrokenError"] = "SubstrateCircuitBrokenError";
    // LTI launch errors
    ErrorCode["CookieNotFound"] = "CookieNotFound";
    ErrorCode["LaunchDetailsNotFound"] = "LaunchDetailsNotFound";
    ErrorCode["RequestValidationFailure"] = "RequestValidationFailure";
    ErrorCode["LtiJwtSecurityTokenValidationFailure"] = "LtiJwtSecurityTokenValidationFailure";
    ErrorCode["LmsJwksNotFoundFailure"] = "LmsJwksNotFoundFailure";
    ErrorCode["LmsJwksNotReachableFailure"] = "LmsJwksNotReachableFailure";
    ErrorCode["LaunchPageUnkownError"] = "LaunchPageUnkownError";
})(ErrorCode || (ErrorCode = {}));
export var SubstrateErrorCode;
(function (SubstrateErrorCode) {
    SubstrateErrorCode["ItemIdNotFound"] = "ItemIdNotFound";
    SubstrateErrorCode["CollectionNotEnabledForSecondaryKeyConstraint"] = "CollectionNotEnabledForSecondaryKeyConstraint";
    SubstrateErrorCode["CollectionIdNotFound"] = "CollectionIdNotFound";
    SubstrateErrorCode["CollectionNotFound"] = "CollectionNotFound";
    SubstrateErrorCode["ErrorInvalidOrgPartition"] = "ErrorInvalidOrgPartition";
    SubstrateErrorCode["ErrorFolderExists"] = "ErrorFolderExists";
    SubstrateErrorCode["CollectionCreationInProgress"] = "CollectionCreationInProgress";
    SubstrateErrorCode["OrganizationFromTenantGuidNotFound"] = "OrganizationFromTenantGuidNotFound";
    SubstrateErrorCode["TooManyRequests"] = "TooManyRequests";
})(SubstrateErrorCode || (SubstrateErrorCode = {}));
export var isIError = function (error) {
    return !!(error && error.code && error.message);
};
/**
 *
 * @param message Error message describing the error
 * @param code Error code indicating the issue
 * @param reason (optional) Meta data about the error that can help with debuging
 * @returns IError: a standardized error object
 */
export function getError(message, code, reason) {
    var error = new Error(message);
    return Object.assign(error, {
        code: code,
        reason: reason,
    });
}
