import { jsx as _jsx } from "react/jsx-runtime";
import { Spinner, SpinnerSize } from "@fluentui/react";
var spinnerStyles = {
    root: {
        padding: "4.8rem 0",
    },
};
/**
 * Implements the Page Loader Renderer
 */
export var PageLoaderRenderer = function () {
    return _jsx(Spinner, { styles: spinnerStyles, size: SpinnerSize.large }, void 0);
};
