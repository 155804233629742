var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { ErrorCode, getError, isIError } from "../../../utilities";
import { ServiceName, apiScenarios, } from "./http.interface";
import { telemetryClient } from "../telemetry";
import { logger } from "../logger";
import { authService } from "../auth";
var serviceEndpoints = (_a = {},
    _a[ServiceName.oneltiBackend] = process.env.ONE_LTI_BACKEND_SERVICE_BASE_URL,
    _a);
var HttpMethod;
(function (HttpMethod) {
    HttpMethod["GET"] = "GET";
    HttpMethod["POST"] = "POST";
    HttpMethod["PATCH"] = "PATCH";
    HttpMethod["DELETE"] = "DELETE";
})(HttpMethod || (HttpMethod = {}));
var fetchWithAuth = function (request, method) { return __awaiter(void 0, void 0, void 0, function () {
    var scenarioName, url, response, searchParams, accessToken, ex_1, code, errorCode, errorMessage, error, data, correlationId, status, responseText, ex_2, error, errorData, errorCode, substrateErrorCode, errorMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                scenarioName = apiScenarios[request.apiName];
                telemetryClient.beginScenario(scenarioName, {
                    ApiName: request.apiName,
                });
                url = "" + serviceEndpoints[ServiceName.oneltiBackend] + request.endpoint;
                if (request.queryParams) {
                    searchParams = new URLSearchParams(request.queryParams);
                    url = url + "?" + searchParams.toString();
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, authService.acquireTokenSilently(scenarioName)];
            case 2:
                accessToken = _a.sent();
                return [4 /*yield*/, fetch(url, __assign({ method: method, credentials: "include", headers: __assign(__assign({}, (method !== HttpMethod.GET
                            ? {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            }
                            : {})), { Authorization: "Bearer " + accessToken }) }, (method !== HttpMethod.GET
                        ? {
                            body: JSON.stringify(request.body),
                        }
                        : {})))];
            case 3:
                response = _a.sent();
                return [3 /*break*/, 5];
            case 4:
                ex_1 = _a.sent();
                code = isIError(ex_1) ? ex_1.code : ErrorCode.Unknown;
                errorCode = window.navigator.onLine === false ? ErrorCode.NetworkOffline : code;
                errorMessage = ex_1.message;
                error = getError(errorMessage, errorCode);
                telemetryClient.failScenario(scenarioName, {
                    ApiName: request.apiName,
                    ErrorCode: errorCode,
                    Message: error.message,
                });
                logger.error(method + " " + request.apiName + " failed: errorCode: " + errorCode + " errorMessage: " + errorMessage);
                throw error;
            case 5:
                correlationId = response.headers.get("x-correlationid") || "";
                status = response.status;
                _a.label = 6;
            case 6:
                _a.trys.push([6, 8, , 9]);
                return [4 /*yield*/, response.text()];
            case 7:
                responseText = _a.sent();
                if (responseText.length) {
                    data = JSON.parse(responseText);
                }
                return [3 /*break*/, 9];
            case 8:
                ex_2 = _a.sent();
                error = getError(ex_2.message, ErrorCode.DeserializationFailed, {
                    correlationId: correlationId,
                    status: status,
                });
                telemetryClient.failScenario(scenarioName, {
                    ApiName: request.apiName,
                    ErrorCode: error.code,
                    Message: error.message,
                    CorrelationId: correlationId,
                    HttpResponseStatus: status,
                });
                logger.error(method + " " + request.apiName + " failed: Failed to deserialize JSON");
                throw error;
            case 9:
                if (response.ok) {
                    telemetryClient.successScenario(scenarioName, {
                        ApiName: request.apiName,
                        CorrelationId: correlationId,
                        HttpResponseStatus: status,
                    });
                }
                else {
                    errorData = data;
                    errorCode = (errorData === null || errorData === void 0 ? void 0 : errorData.ErrorCode) || ErrorCode.FailedHttpStatus;
                    substrateErrorCode = (errorData === null || errorData === void 0 ? void 0 : errorData.SubstrateErrorCode) || null;
                    errorMessage = (errorData === null || errorData === void 0 ? void 0 : errorData.Message) || "Recieved non-200 response";
                    telemetryClient.failScenario(scenarioName, {
                        ApiName: request.apiName,
                        ErrorCode: errorCode,
                        SubstrateErrorCode: substrateErrorCode,
                        Message: errorMessage,
                        CorrelationId: correlationId,
                        HttpResponseStatus: status,
                    });
                }
                return [2 /*return*/, {
                        ok: response.ok,
                        data: data,
                        status: status,
                        correlationId: correlationId,
                    }];
        }
    });
}); };
/**
 * HTTP client that can be used for making API calls.
 */
export var httpClient = {
    get: function (request) {
        return fetchWithAuth(request, HttpMethod.GET);
    },
    post: function (request) {
        return fetchWithAuth(request, HttpMethod.POST);
    },
    patch: function (request) {
        return fetchWithAuth(request, HttpMethod.PATCH);
    },
    delete: function (request) {
        return fetchWithAuth(request, HttpMethod.DELETE);
    },
};
