/**********************************************************************
 * <copyright file='office-ui-fabric-setup.ts' company='Microsoft'>
 * © Microsoft. All rights reserved.
 * </copyright>
 **********************************************************************/
// This code configures the Content-Security-Policy nonce setting for Office UI Fabric.
// This file must be imported at the beginning of index.tsx so that it loads and sets the nonce values before the Office-Ui-Fabric module is loaded.
// Set the nonce for merge-styles.
window.FabricConfig = {
    mergeStyles: {
        cspSettings: { nonce: __webpack_nonce__ },
    },
};
// Set the nonce for Stylesheet.
window.CSPSettings = {
    nonce: __webpack_nonce__,
};
export {};
