var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { TelemetryType, } from "./telemetry.interface";
import { ApplicationInsights } from "@microsoft/1ds-analytics-web-js";
import { EmeaOneDSConfiguration, GlobalOneDSConfiguration, TelemetryInitializer, } from "./telemetry.utils";
import { logger } from "../logger";
import { ScenarioState, } from "./telemetry.interface";
import { Scenario } from "./scenario";
import { TenantDataBoundary, TenantDataBoundaryService, } from "../tenant-data-boundary";
import { v4 as uuid } from "uuid";
var OneLTIScenarioTelemetry = "OneLTITelemetry";
var TelemetryClient = /** @class */ (function () {
    function TelemetryClient() {
        this.InMemoryStore = new Map();
        this.sessionId = uuid();
        this.globalAppInsights = new ApplicationInsights();
        this.emeaAppInsights = new ApplicationInsights();
        //Initialize SDK
        this.globalAppInsights.initialize(GlobalOneDSConfiguration, []);
        this.globalAppInsights.addTelemetryInitializer(TelemetryInitializer);
        this.emeaAppInsights.initialize(EmeaOneDSConfiguration, []);
        this.emeaAppInsights.addTelemetryInitializer(TelemetryInitializer);
        this.beginScenario("ApplicationPageLoad");
    }
    TelemetryClient.prototype.getSessionId = function () {
        return this.sessionId;
    };
    TelemetryClient.prototype.beginScenario = function (scenarioName, properties) {
        var _this = this;
        var scenario = this.createScenario(scenarioName);
        if (!scenario) {
            return;
        }
        // End the scenario before app unloads
        window.onbeforeunload = function () {
            var _scenario = _this.getScenario(scenarioName);
            if (_scenario && _scenario.state === ScenarioState.Started) {
                _this.endScenario(scenarioName, ScenarioState.Unknown);
            }
        };
        this.logScenarioStep(scenarioName, "BEGIN", __assign({ ScenarioState: ScenarioState.Started }, (properties || {})));
    };
    TelemetryClient.prototype.failScenario = function (scenarioName, properties) {
        this.endScenario(scenarioName, ScenarioState.Failed, properties);
    };
    TelemetryClient.prototype.timeoutScenario = function (scenarioName, properties) {
        this.endScenario(scenarioName, ScenarioState.Timeout, properties);
    };
    TelemetryClient.prototype.successScenario = function (scenarioName, properties) {
        this.endScenario(scenarioName, ScenarioState.Success, properties);
    };
    TelemetryClient.prototype.cancelledScenario = function (scenarioName, properties) {
        this.endScenario(scenarioName, ScenarioState.Cancelled, properties);
    };
    TelemetryClient.prototype.unknownEndScenario = function (scenarioName, properties) {
        this.endScenario(scenarioName, ScenarioState.Unknown, properties);
    };
    TelemetryClient.prototype.logScenarioStep = function (scenarioName, stepName, properties) {
        properties = properties || {};
        var scenario = this.getScenario(scenarioName);
        if (!scenario) {
            logger.error("Scenario " + scenarioName + " not found. Cannot log step for an undefined scenario.");
            this.logError("Step for scenario " + scenarioName + " cannot be logged.");
            return;
        }
        var currentTime = performance.now();
        properties.Duration = currentTime - scenario.latestEventLoggingTime;
        scenario.latestEventLoggingTime = currentTime;
        this.updateScenario(scenarioName, scenario);
        this.logEvent(__assign({ EventName: "LogScenarioStep", StepName: stepName, ScenarioName: scenario.scenarioName, EventTimestamp: currentTime }, properties), TelemetryType.ScenarioStep);
    };
    TelemetryClient.prototype.logEvent = function (props, type) {
        props.OneLtiSessionId = this.sessionId;
        props.Type = type || TelemetryType.Event;
        props.EventTimestamp = props.EventTimestamp || performance.now();
        var isScenario = props.Type === TelemetryType.ScenarioStep;
        logger.debug(isScenario
            ? "Scenario-" + props.ScenarioName
            : "Event-" + props.EventName, props);
        if (process.env.DEBUG === "true") {
            var markName = isScenario
                ? props.ScenarioName + "-" + props.StepName
                : "" + props.EventName;
            performance.mark(markName);
            if (isScenario && props.ScenarioName && props.StepName === "END") {
                performance.measure(props.ScenarioName, props.ScenarioName + "-BEGIN", markName);
            }
        }
        if (process.env.IS_EUDB_TELEMETRY_ROUTING_ENABLED === "true") {
            var dataBoundaryForTelemetry = TenantDataBoundaryService.getTenantDataBoundary();
            if (dataBoundaryForTelemetry === TenantDataBoundary.EMEA) {
                logger.debug("Log to EMEA: " + props.ScenarioName + " " + props.EventName);
                this.emeaAppInsights.track({
                    name: OneLTIScenarioTelemetry,
                    data: props,
                });
            }
            else {
                logger.debug("Log to GLOBAL: " + props.ScenarioName + " " + props.EventName);
                if (dataBoundaryForTelemetry === TenantDataBoundary.UNKNOWN) {
                    this.globalAppInsights.track({
                        name: OneLTIScenarioTelemetry,
                        data: __assign(__assign({}, props), { AzureSubjectId: "" }),
                    });
                }
                else {
                    this.globalAppInsights.track({
                        name: OneLTIScenarioTelemetry,
                        data: props,
                    });
                }
            }
        }
        else {
            logger.debug("Log to GLOBAL: " + props.ScenarioName + " " + props.EventName);
            this.globalAppInsights.track({
                name: OneLTIScenarioTelemetry,
                data: props,
            });
        }
    };
    TelemetryClient.prototype.createScenario = function (scenarioName) {
        var scenario = new Scenario(scenarioName);
        if (this.InMemoryStore.get(scenarioName)) {
            logger.error("A scenario of type " + scenarioName + " already exists.");
            this.logError("The scenario " + scenarioName + " already exists.");
            return null;
        }
        this.InMemoryStore.set(scenarioName, scenario);
        return scenario;
    };
    TelemetryClient.prototype.updateScenario = function (scenarioName, scenario) {
        this.InMemoryStore.set(scenarioName, scenario);
    };
    TelemetryClient.prototype.removeScenario = function (scenarioName) {
        this.InMemoryStore.delete(scenarioName);
    };
    TelemetryClient.prototype.getScenario = function (scenarioName) {
        return this.InMemoryStore.get(scenarioName);
    };
    TelemetryClient.prototype.logError = function (message, item) {
        this.logEvent(__assign({ EventName: "TelemetryErrorLog", TelemetryLogLevel: "Error", Message: message }, (item || {})));
    };
    TelemetryClient.prototype.endScenario = function (scenarioName, endState, properties) {
        var scenario = this.getScenario(scenarioName);
        if (scenario && scenario.state == ScenarioState.Started) {
            scenario.scenarioEndTime = performance.now();
            scenario.state = endState;
            var totalDuration = scenario.scenarioEndTime - scenario.scenarioBeginTime;
            this.logScenarioStep(scenarioName, "END", __assign({ ScenarioState: endState, TotalDuration: totalDuration }, (properties || {})));
            this.removeScenario(scenarioName);
        }
        else {
            var isScenarioEndCallExpectedWithoutStart = 
            /**
             * Application Page Load may be called redundantly when
             * navigating from landing page to registration page
             */
            scenarioName === "ApplicationPageLoad" ||
                /**
                 * GoToRegistrationPortalClick may be called redundantly
                 * when registration page is loading.
                 */
                scenarioName === "GoToRegistrationPortalClick";
            if (!isScenarioEndCallExpectedWithoutStart) {
                logger.error("Scenario " + scenarioName + " not found, or it hasn't been started yet.");
                this.logError("Can not end Scenario " + scenarioName + ".");
            }
        }
    };
    return TelemetryClient;
}());
export var telemetryClient = new TelemetryClient();
var TelemetryClientContext = React.createContext({
    telemetryClient: telemetryClient,
});
export var TelemetryProvider = React.memo(function (_a) {
    var children = _a.children;
    return (_jsx(TelemetryClientContext.Provider, __assign({ value: { telemetryClient: telemetryClient } }, { children: children }), void 0));
});
TelemetryProvider.displayName = "OneDSProvider";
export var useTelemetryClient = function () {
    return React.useContext(TelemetryClientContext);
};
