var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PrimaryButton, Stack, Text, Image, Link, } from "@fluentui/react";
import { TranslationKeys, useI18n } from "../../../core/services";
var errorPageStackStyles = {
    root: {
        width: "100%",
        maxWidth: "72.8rem",
        margin: "14rem auto",
    },
};
var errorMessageHeaderStyles = {
    root: {
        fontWeight: "bold",
        marginTop: "3.6rem",
        marginBottom: "2rem",
        whiteSpace: "pre-wrap",
        textAlign: "center",
    },
};
var imageStyles = {
    root: {
        height: "100%",
    },
};
/**
 * Implements the Error renderer
 */
export var ErrorPageRenderer = function (props) {
    var translate = useI18n().translate;
    return (_jsxs(Stack, __assign({ styles: errorPageStackStyles, as: "main" }, { children: [_jsx(Stack.Item, __assign({ align: "center" }, { children: _jsx(Image, { alt: props.errorImageProperties.alt, src: props.errorImageProperties.imagePath, styles: imageStyles }, void 0) }), void 0), _jsx(Stack.Item, __assign({ align: "center" }, { children: _jsxs(Text, __assign({ block: true, styles: errorMessageHeaderStyles, as: "h1" }, { children: [props.errorMessage, props.errorTroubleshootingLink && (_jsx(Text, __assign({ block: true }, { children: _jsx(Link, __assign({ href: props.errorTroubleshootingLink, target: "_blank", rel: "noreferrer noopener", onClick: props.onErrorTsgLinkClick }, { children: translate(TranslationKeys.tsgLink) }), void 0) }), void 0))] }), void 0) }), void 0), _jsx(Stack.Item, __assign({ align: "center" }, { children: props.errorDescription && (_jsx(Text, __assign({ block: true }, { children: props.errorDescription }), void 0)) }), void 0), props.action && (_jsx(Stack.Item, __assign({ align: "center" }, { children: _jsx(PrimaryButton, __assign({ onClick: props.action }, { children: props.actionButtonText }), void 0) }), void 0))] }), void 0));
};
