import { ScenarioState } from "./telemetry.interface";
var Scenario = /** @class */ (function () {
    function Scenario(scenarioName) {
        this.scenarioBeginTime = 0;
        this.scenarioEndTime = 0;
        this.latestEventLoggingTime = 0;
        this.scenarioName = scenarioName;
        this.state = ScenarioState.Started;
        this.scenarioBeginTime = performance.now();
        this.latestEventLoggingTime = this.scenarioBeginTime;
    }
    return Scenario;
}());
export { Scenario };
