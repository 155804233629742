var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { LogLevel, } from "./logger.interface";
var Logger = /** @class */ (function () {
    function Logger() {
    }
    Logger.prototype.log = function (message, properties) {
        this.logMessage(LogLevel.INFO, message, properties);
    };
    Logger.prototype.error = function (message, properties) {
        this.logMessage(LogLevel.ERROR, message, properties);
    };
    Logger.prototype.warn = function (message, properties) {
        this.logMessage(LogLevel.WARNING, message, properties);
    };
    Logger.prototype.debug = function (message, properties) {
        this.logMessage(LogLevel.DEBUG, message, properties);
    };
    Logger.prototype.logMessage = function (level, message, properties) {
        if (properties === void 0) { properties = {}; }
        if (process.env.DEBUG === "true") {
            var formattedMessage = "[OneLTI][" + level + "]: " + message;
            // eslint-disable-next-line no-console
            console.log(formattedMessage, properties);
        }
    };
    return Logger;
}());
export var logger = new Logger();
var LoggerContext = React.createContext({
    logger: logger,
});
export var LoggerProvider = React.memo(function (_a) {
    var children = _a.children;
    return (_jsx(LoggerContext.Provider, __assign({ value: { logger: logger } }, { children: children }), void 0));
});
LoggerProvider.displayName = "LoggerProvider";
export var useLogger = function () {
    return React.useContext(LoggerContext);
};
