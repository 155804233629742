export var TelemetryType;
(function (TelemetryType) {
    TelemetryType["ScenarioStep"] = "ScenarioStep";
    TelemetryType["Event"] = "Event";
})(TelemetryType || (TelemetryType = {}));
export var ScenarioState;
(function (ScenarioState) {
    ScenarioState["Started"] = "STARTED";
    ScenarioState["Success"] = "SUCCESS";
    ScenarioState["Failed"] = "FAILED";
    ScenarioState["Timeout"] = "TIMEOUT";
    ScenarioState["Unknown"] = "UNKNOWN";
    ScenarioState["Cancelled"] = "CANCELLED";
})(ScenarioState || (ScenarioState = {}));
