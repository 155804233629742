var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { msalConfig, loginRequest, silentRequest, logoutRequest, } from "./auth-config";
import { PublicClientApplication, InteractionRequiredAuthError, } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ErrorCode, getError } from "../../../utilities";
import { logger } from "../logger";
import { telemetryClient } from "../telemetry";
/**
 * Authentication service provides authentication and authorization
 */
var AuthService = /** @class */ (function () {
    // Create MSAL instance
    function AuthService() {
        var _this = this;
        this.getMsalInstance = function () { return _this.msalInstance; };
        this.getActiveAccount = function () {
            return _this.msalInstance.getActiveAccount();
        };
        /**
         * MSAL Secure sign in to the Portal
         * Login Flow
         * Step 1. Call sso silent to validate authentication(If there is a session that exists with the authentication server then authenticate user and make requests for tokens without interaction.)
         * Step 2. If step1 fails then go with interactive login redirect flow
         * Step 3. If step1 or step2 passes then pass the token to backend Api to check if the user is authorised or not
         * Step 4. If step3 fails then return account error so that the user will be redirected to the authorization error screen
         *
         */
        this.login = function () { return __awaiter(_this, void 0, void 0, function () {
            var exception_1, exception_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 7]);
                        telemetryClient.beginScenario("MSALLogin");
                        return [4 /*yield*/, this.silentLogin()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 2:
                        exception_1 = _a.sent();
                        logger.error("Silent Login failed with exception:  " + JSON.stringify(exception_1));
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, this.interactiveLogin()];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        exception_2 = _a.sent();
                        logger.error("InteractiveLogin failed with exception:  " + JSON.stringify(exception_2));
                        return [3 /*break*/, 6];
                    case 6: return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        /**
         * MSAL Secure sign out of the Portal
         */
        this.logOut = function () { return __awaiter(_this, void 0, void 0, function () {
            var currentAccount, homeAccountId, exception_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        telemetryClient.beginScenario("MSALLogout");
                        currentAccount = this.msalInstance.getActiveAccount();
                        // We logout of current account if available.
                        // Otherwise log out of all accounts in LTI portal.
                        if (currentAccount) {
                            homeAccountId = currentAccount.homeAccountId;
                            logoutRequest.account =
                                this.msalInstance.getAccountByHomeId(homeAccountId);
                        }
                        telemetryClient.successScenario("MSALLogout", {
                            EventName: "InteractiveLogoutAttempted",
                            Message: currentAccount ? "ActiveAccountFound" : "NoAccountFound",
                        });
                        return [4 /*yield*/, this.msalInstance.logoutRedirect(logoutRequest)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        exception_3 = _a.sent();
                        logger.error("Logout error: " + JSON.stringify(exception_3));
                        telemetryClient.logEvent({
                            EventName: "LogoutFailure",
                            Message: exception_3.message,
                            ErrorCode: exception_3.errorCode
                                ? exception_3.errorCode
                                : ErrorCode.AuthErrorLogoutFailed,
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        /**
         * Step -1 Login
         * First silently login user, if fails then go for interactive login
         */
        this.silentLogin = function () { return __awaiter(_this, void 0, void 0, function () {
            var silentLoginRequest, exception_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        silentLoginRequest = silentRequest;
                        telemetryClient.logScenarioStep("MSALLogin", "SilentLoginStart");
                        if (!(this.msalInstance.getAllAccounts() &&
                            Array.isArray(this.msalInstance.getAllAccounts()) &&
                            this.msalInstance.getAllAccounts()[0])) return [3 /*break*/, 2];
                        silentLoginRequest.account = this.msalInstance.getAllAccounts()[0];
                        return [4 /*yield*/, this.msalInstance.ssoSilent(silentLoginRequest)];
                    case 1:
                        _a.sent();
                        telemetryClient.logScenarioStep("MSALLogin", "SilentLoginEnd", {
                            EventName: "SilentLoginSuccess",
                        });
                        telemetryClient.successScenario("MSALLogin");
                        return [3 /*break*/, 3];
                    case 2: throw getError("Interaction_required", ErrorCode.AuthErrorSilentSSO);
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        exception_4 = _a.sent();
                        logger.error("Error in silent SSO:  " + JSON.stringify(exception_4));
                        telemetryClient.logScenarioStep("MSALLogin", "SilentLoginEnd", {
                            EventName: "SilentLoginFailure",
                            Message: exception_4.message,
                            ErrorCode: exception_4.errorCode
                                ? exception_4.errorCode
                                : ErrorCode.AuthErrorSilentSSO,
                        });
                        throw getError(exception_4.message, ErrorCode.AuthErrorSilentSSO);
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        /**
         * Acquire Token silently(no interaction)
         * Throw error/exception to the calling component to handle the api request by passing this token in authorization header
         */
        this.acquireTokenSilently = function (scenarioName) { return __awaiter(_this, void 0, void 0, function () {
            var accessToken, acquireTokenSilentRequest, response, exception_5, errCode;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accessToken = "";
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        telemetryClient.logScenarioStep(scenarioName, "AcquireTokenSilentlyStart");
                        acquireTokenSilentRequest = silentRequest;
                        acquireTokenSilentRequest.account = this.msalInstance.getAllAccounts()[0];
                        return [4 /*yield*/, msalInstance.acquireTokenSilent(acquireTokenSilentRequest)];
                    case 2:
                        response = _a.sent();
                        accessToken = response === null || response === void 0 ? void 0 : response.accessToken;
                        telemetryClient.logScenarioStep(scenarioName, "AcquireTokenSilentlyEnd", {
                            EventName: "AcquireTokenSilentlySuccess",
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        exception_5 = _a.sent();
                        errCode = void 0;
                        logger.error("Error in acquire token silently:  " + JSON.stringify(exception_5));
                        if (exception_5 instanceof InteractionRequiredAuthError) {
                            errCode = ErrorCode.AuthErrorInteractionRequired;
                        }
                        else {
                            errCode = ErrorCode.AuthErrorAcquireTokenSilently;
                        }
                        telemetryClient.logScenarioStep(scenarioName, "AcquireTokenSilentlyEnd", {
                            EventName: "AcquireTokenSilentlyFailure",
                            Message: exception_5.message,
                            MsalErrorCode: exception_5.errorCode,
                            ErrorCode: errCode,
                        });
                        throw getError(exception_5.message, errCode);
                    case 4: return [2 /*return*/, accessToken];
                }
            });
        }); };
        /**
         * Login redirect flow secure login using MSAL.js(2.0)
         */
        this.interactiveLogin = function () { return __awaiter(_this, void 0, void 0, function () {
            var exception_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        telemetryClient.successScenario("MSALLogin", {
                            EventName: "InteractiveLoginAttempted",
                        });
                        return [4 /*yield*/, this.msalInstance.loginRedirect(__assign(__assign({}, loginRequest), { prompt: "select_account" }))];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        exception_6 = _a.sent();
                        logger.error("Error in Login interactively:  " + JSON.stringify(exception_6));
                        telemetryClient.logEvent({
                            ComponentName: "Auth",
                            EventName: "InteractiveLoginFailure",
                            Message: exception_6.message,
                            ErrorCode: ErrorCode.AuthErrorInteractiveLoginFailed,
                            MsalErrorCode: exception_6.errorCode,
                        });
                        throw getError(exception_6.message, ErrorCode.AuthErrorInteractiveLoginFailed);
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.msalInstance = new PublicClientApplication(msalConfig);
    }
    return AuthService;
}());
/**
 * Create auth service context
 */
export var authService = new AuthService();
export var authContext = React.createContext({
    authService: authService,
});
var msalInstance = authService.getMsalInstance();
export var AuthProvider = React.memo(function (_a) {
    var children = _a.children;
    return (_jsx(authContext.Provider, __assign({ value: {
            authService: authService,
        } }, { children: _jsx(MsalProvider, __assign({ instance: msalInstance }, { children: children }), void 0) }), void 0));
});
AuthProvider.displayName = "AuthProvider";
export var useAuth = function () {
    return React.useContext(authContext);
};
