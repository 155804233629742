var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from "react";
import i18n from "i18next";
import { SupportedLanguageCodes, DateTimeFormats, fallbackLanguageCodes, } from "./i18n.interface";
import fallbackTranslations from "./translations.json";
import { telemetryClient } from "../telemetry";
import { ErrorCode } from "../../../utilities";
import { setRTL } from "@fluentui/utilities";
/**
 *
 * @param languageCode
 * @returns Checks if given language code's is supported by returning the language translation code.
 */
function getLanguageIfSupported(languageCode) {
    var languageIndex = SupportedLanguageCodes.findIndex(function (code) { return code.toLowerCase() === languageCode.toLowerCase(); });
    if (languageIndex !== -1) {
        return SupportedLanguageCodes[languageIndex];
    }
    // Check if fallback mapping exists
    var fallbackLanguage = fallbackLanguageCodes.find(function (fallbackLanguage) {
        return fallbackLanguage.code.toLowerCase() === languageCode.toLowerCase();
    });
    if (fallbackLanguage) {
        return fallbackLanguage.translationCode;
    }
    return null;
}
/**
 * Format function. Currently used for dates only.
 * @param value Value to be formatted
 * @param format DateTime format
 * @param lng Language being rendered
 * @returns formatted date string
 */
export var i18nFormatFunction = function (value, format, lng) {
    if (value instanceof Date) {
        if (format === DateTimeFormats.SHORT_TEXT_DATE) {
            var date = value.toLocaleDateString(lng, {
                day: "numeric",
                month: "long",
                year: "numeric",
            });
            return date;
        }
        return value.toLocaleDateString(lng);
    }
    return value;
};
/**
 * @returns User's language code based on browser/OS settings.
 * Returns null if not supported.
 */
export function getUserLanguageCode() {
    var userBrowserLanguageCode = window.navigator.language;
    var userRegionalLanguage = getLanguageIfSupported(userBrowserLanguageCode);
    if (userRegionalLanguage) {
        return userRegionalLanguage;
    }
    // Check if language is supported even though region may not be supported.
    var userBrowserLanguage = (userBrowserLanguageCode || "").split("-")[0];
    var userLanguage = getLanguageIfSupported(userBrowserLanguage);
    if (userLanguage) {
        return userLanguage;
    }
    return null;
}
/**
 * Initialise I18n library: load the user locale if supported
 * Else fallback to english
 */
export function initI18n() {
    return __awaiter(this, void 0, void 0, function () {
        var userLanguageCode, userLanguageTranslation, module_1, e_1, resolvedLangCode, dir, e_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    telemetryClient.beginScenario("InitializeI18n");
                    userLanguageCode = getUserLanguageCode();
                    userLanguageTranslation = null;
                    if (!(userLanguageCode !== "en")) return [3 /*break*/, 6];
                    if (!userLanguageCode) return [3 /*break*/, 5];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    telemetryClient.logScenarioStep("InitializeI18n", "LanguageBundleLoadStart");
                    return [4 /*yield*/, import(process.env.LOCALES_BASE_PATH + "/" + userLanguageCode + "/translations.json")];
                case 2:
                    module_1 = _b.sent();
                    userLanguageTranslation = module_1.default;
                    telemetryClient.logScenarioStep("InitializeI18n", "LanguageBundleLoadEnd");
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    telemetryClient.logScenarioStep("InitializeI18n", "LanguageBundleLoadError", {
                        Message: e_1.message,
                    });
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 6];
                case 5:
                    telemetryClient.logScenarioStep("InitializeI18n", "LanguageTranslationMissingError");
                    _b.label = 6;
                case 6:
                    resolvedLangCode = userLanguageCode || "en";
                    _b.label = 7;
                case 7:
                    _b.trys.push([7, 9, , 10]);
                    document.documentElement.lang = resolvedLangCode;
                    return [4 /*yield*/, i18n.init({
                            debug: process.env.DEBUG === "true",
                            resources: __assign(__assign({}, (userLanguageCode && userLanguageTranslation
                                ? (_a = {},
                                    _a[userLanguageCode] = {
                                        translation: userLanguageTranslation,
                                    },
                                    _a) : {})), { en: {
                                    translation: fallbackTranslations,
                                } }),
                            lng: resolvedLangCode,
                            fallbackLng: "en",
                            saveMissing: true,
                            interpolation: {
                                format: i18nFormatFunction,
                                alwaysFormat: true,
                                escapeValue: false,
                            },
                        })];
                case 8:
                    _b.sent();
                    dir = i18n.dir();
                    setRTL(dir === "rtl");
                    telemetryClient.successScenario("InitializeI18n");
                    return [3 /*break*/, 10];
                case 9:
                    e_2 = _b.sent();
                    telemetryClient.failScenario("InitializeI18n", {
                        ErrorCode: ErrorCode.I18nInitFailure,
                        Message: e_2.message,
                    });
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    });
}
var I18nContext = React.createContext({
    translate: function (key) { return key; },
    currentLanguageCode: "en",
});
export var I18nLoader = React.memo(function (_a) {
    var children = _a.children, loader = _a.loader;
    var _b = useState(true), isLoading = _b[0], setLoadingState = _b[1];
    useEffect(function () {
        var setupI18n = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, initI18n()];
                    case 1:
                        _a.sent();
                        setLoadingState(false);
                        return [2 /*return*/];
                }
            });
        }); };
        setupI18n();
    }, [setLoadingState]);
    if (isLoading) {
        return loader;
    }
    return _jsx(_Fragment, { children: children }, void 0);
});
I18nLoader.displayName = "I18nLoader";
export var I18nProvider = React.memo(function (_a) {
    var children = _a.children;
    var translate = useCallback(function (key, params) { return i18n.t(key, { replace: params || {} }); }, []);
    var currentLanguageCode = i18n.language;
    return (_jsx(I18nContext.Provider, __assign({ value: { translate: translate, currentLanguageCode: currentLanguageCode } }, { children: children }), void 0));
});
I18nProvider.displayName = "I18nProvider";
export var useI18n = function () {
    return React.useContext(I18nContext);
};
