var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { TranslationKeys, useI18n } from "../../../core/services";
import { Link, Stack, Text, useTheme } from "@fluentui/react";
/**
 * Implements the Footer Container component.
 */
export var FooterContainer = function () {
    var _a = useI18n(), translate = _a.translate, currentLanguageCode = _a.currentLanguageCode;
    var theme = useTheme();
    var footerPrivacy = translate(TranslationKeys.footerPrivacy);
    var footerTermsOfUse = translate(TranslationKeys.footerTermsOfUse);
    var footerCopyright = translate(TranslationKeys.footerCopyright);
    var accessibilityLabel = translate(TranslationKeys.accessibilityLabel);
    var isCurrentLanguageItaly = currentLanguageCode
        .toLowerCase()
        .startsWith("it");
    var footerContainerStyles = useMemo(function () { return ({
        root: {
            background: theme.palette.neutralLight,
            height: "6.4rem",
            padding: "1rem",
        },
    }); }, [theme]);
    var textStyles = useMemo(function () { return ({
        root: {
            color: theme.palette.black,
            margin: "0 1.6rem",
            fontSize: "1.4rem",
        },
    }); }, [theme]);
    var linkStyles = useMemo(function () { return ({
        root: __assign(__assign({}, textStyles.root), { textDecoration: "underline" }),
    }); }, [theme, textStyles]);
    return (_jsxs(Stack, __assign({ horizontal: true, styles: footerContainerStyles, verticalAlign: "center", horizontalAlign: "center", as: "footer" }, { children: [_jsx(Stack.Item, { children: _jsx(Link, __assign({ styles: linkStyles, href: "https://go.microsoft.com/fwlink/?LinkId=521839", rel: "noreferrer noopener", target: "_blank" }, { children: footerPrivacy }), void 0) }, void 0), isCurrentLanguageItaly && (_jsx(Stack.Item, { children: _jsx(Link, __assign({ styles: linkStyles, href: "https://go.microsoft.com/fwlink/?linkid=2121429", rel: "noreferrer noopener", target: "_blank" }, { children: accessibilityLabel }), void 0) }, void 0)), _jsx(Stack.Item, { children: _jsx(Link, __assign({ styles: linkStyles, href: "https://go.microsoft.com/fwlink/?LinkID=206977", rel: "noreferrer noopener", target: "_blank" }, { children: footerTermsOfUse }), void 0) }, void 0), _jsx(Stack.Item, { children: _jsx(Text, __assign({ styles: textStyles }, { children: footerCopyright }), void 0) }, void 0)] }), void 0));
};
