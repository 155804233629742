var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from "react";
import { ContextualMenu, FontIcon, DirectionalHint, mergeStyles, useTheme, ActionButton, } from "@fluentui/react";
import { TranslationKeys, useAuth, useI18n, useTelemetryClient, } from "../../../core/services";
import { useReportAProblem } from "../report-problem";
export var HelpMenuContainer = function () {
    var theme = useTheme();
    var translate = useI18n().translate;
    var telemetryClient = useTelemetryClient().telemetryClient;
    var authService = useAuth().authService;
    var userAccount = authService.getActiveAccount();
    var linkRef = React.useRef(null);
    var _a = useState(false), showContextualMenu = _a[0], setShowContextualMenu = _a[1];
    var onShowContextualMenu = React.useCallback(function () {
        setShowContextualMenu(true);
    }, []);
    var onHideContextualMenu = React.useCallback(function () { return setShowContextualMenu(false); }, []);
    var iconWrapperStyles = mergeStyles({
        color: theme.palette.white,
        height: "4.8rem",
        padding: "0 1.4rem",
        fontSize: "1.8rem",
        ":hover": {
            color: theme.palette.white,
        },
        ".ms-Fabric--isFocusVisible &": {
            ":focus::after": {
                outline: theme.palette.white + " 0.1rem solid",
            },
        },
        "div.fontIconWrapper": {
            height: "4.8rem",
            lineHeight: "4.8rem",
        },
    });
    var reportAProblemLink = useReportAProblem();
    var menuItems = useMemo(function () {
        var items = [
            {
                key: "docsForAdmin",
                text: translate(TranslationKeys.helpForAdmins),
                href: "https://docs.microsoft.com/microsoft-365/lti/",
                target: "_blank",
                onClick: function () {
                    telemetryClient.logEvent({
                        EventName: "DocsForAdminClick",
                    });
                },
            },
            /**
             * TODO: Add a single link for educators once help docs are consilidated.
             * Requirement 131562: Documentation hub within OneLTI portal
             */
            // {
            //   key: "docsForEducators",
            //   text: translate(TranslationKeys.helpForEducators),
            //   href: "https://bing.com",
            //   target: "_blank",
            // },
        ];
        if (userAccount) {
            items.push({
                key: "reportAProblem",
                text: translate(TranslationKeys.reportProblem),
                href: reportAProblemLink,
                target: "_blank",
                onClick: function () {
                    telemetryClient.logEvent({
                        EventName: "ReportAProblemClick",
                    });
                },
            });
        }
        return items;
    }, [translate, userAccount, telemetryClient, reportAProblemLink]);
    var helpButtonAriaLabel = translate(TranslationKeys.helpButtonAriaLabel);
    return (_jsxs(_Fragment, { children: [_jsx(ActionButton, __assign({ onClick: onShowContextualMenu, className: iconWrapperStyles, ariaLabel: helpButtonAriaLabel }, { children: _jsx("div", __assign({ className: "fontIconWrapper", ref: linkRef }, { children: _jsx(FontIcon, { iconName: "Help" }, void 0) }), void 0) }), void 0), _jsx(ContextualMenu, { items: menuItems, hidden: !showContextualMenu, target: linkRef, onItemClick: onHideContextualMenu, onDismiss: onHideContextualMenu, directionalHint: DirectionalHint.bottomRightEdge }, void 0)] }, void 0));
};
