var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { initializeIcons } from "@fluentui/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LoggerProvider } from "./core/services/";
import { I18nProvider, I18nLoader } from "./core/services/";
import { TelemetryProvider } from "./core/services/";
import { ErrorBoundary } from "./components/common/error-boundary";
import { PageLoaderRenderer } from "./components/common/page-loader";
import { AuthorisedRoute } from "./components/common/authorised-route";
import { AuthProvider } from "./core/services/";
import { LazyLoaderContainer, } from "./components/common/lazy-loader";
import { TenantDataBoundaryProvider } from "./core/services";
initializeIcons();
var landingPageLoaderFactory = function () {
    return import(/* webpackChunkName: "landing-page" */ "./components/landing-page");
};
var homePageLoaderFactory = function () { return import(/* webpackChunkName: "home-page" */ "./components/home-page"); };
var errorPageLoaderFactory = function () {
    return import(
    /* webpackChunkName: "launch-error-page" */ "./components/launch-error-page");
};
var i18nLoader = _jsx(PageLoaderRenderer, {}, void 0);
var errorBoundaryTelemetryData = {
    ComponentName: "App",
};
/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
    var isLtiLaunchError = window.LTI_LAUNCH_ERROR_CODE !== "";
    return (_jsx(AuthProvider, { children: _jsx(TenantDataBoundaryProvider, { children: _jsx(Router, { children: _jsx(LoggerProvider, { children: _jsx(TelemetryProvider, { children: _jsx(I18nLoader, __assign({ loader: i18nLoader }, { children: _jsx(I18nProvider, { children: _jsx(ErrorBoundary, __assign({ telemetryData: errorBoundaryTelemetryData }, { children: isLtiLaunchError ? (_jsx(Switch, { children: _jsx(Route, { children: _jsx(LazyLoaderContainer, { lazyLoadFactory: errorPageLoaderFactory, componentName: "LaunchErrorPageContainer" }, void 0) }, void 0) }, void 0)) : (_jsxs(Switch, { children: [_jsx(Route, __assign({ exact: true, path: "/" }, { children: _jsx(LazyLoaderContainer, { lazyLoadFactory: landingPageLoaderFactory, componentName: "LandingPageContainer" }, void 0) }), void 0), _jsx(AuthorisedRoute, __assign({ path: "/registration" }, { children: _jsx(LazyLoaderContainer, { lazyLoadFactory: homePageLoaderFactory, componentName: "HomePageContainer" }, void 0) }), void 0)] }, void 0)) }), void 0) }, void 0) }), void 0) }, void 0) }, void 0) }, void 0) }, void 0) }, void 0));
}
