var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Stack, Persona, PersonaSize, useTheme, Image, ActionButton, mergeStyles, Link, } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { TranslationKeys, useAuth, useI18n, useTelemetryClient, } from "../../../core/services";
import { UserMenuContainer } from "../user-menu";
import { HelpMenuContainer } from "../help-menu";
import { useHistory } from "react-router-dom";
var headerImageStyles = {
    image: {
        height: "2.8rem",
    },
};
/**
 * Implements the Header Container component
 */
export var HeaderContainer = function (props) {
    var _a = useBoolean(false), isUserMenuCalloutVisible = _a[0], toggleIsUserMenuCalloutVisible = _a[1].toggle;
    var theme = useTheme();
    var history = useHistory();
    var telemetryClient = useTelemetryClient().telemetryClient;
    var userMenuActionId = useId("user-menu-action");
    var currentTheme = useTheme();
    var authService = useAuth().authService;
    var userAccount = authService.getActiveAccount();
    var translate = useI18n().translate;
    var companyLogoAltDescription = translate(TranslationKeys.companyLogoAltDescription);
    var userMenuAriaLabel = translate(TranslationKeys.userMenuButtonAriaLabel);
    var personaAlt = translate(TranslationKeys.personaAlt);
    var toggleUserMenuCalloutCallback = useCallback(function () {
        if (isUserMenuCalloutVisible) {
            telemetryClient.logEvent({
                EventName: "UserMenuClose",
                ComponentName: "UserMenuContainer",
            });
        }
        else {
            telemetryClient.logEvent({
                EventName: "UserMenuOpen",
                ComponentName: "UserMenuContainer",
            });
        }
        toggleIsUserMenuCalloutVisible();
    }, [
        isUserMenuCalloutVisible,
        telemetryClient,
        toggleIsUserMenuCalloutVisible,
    ]);
    var onGoToLandingPageClick = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var landingPagePath;
        return __generator(this, function (_a) {
            if (!props.disableHeaderActions) {
                landingPagePath = process.env.DEVELOPER_PREVIEW_PARAM === "true"
                    ? "/?microsoft-onelti-developer=true"
                    : "/";
                telemetryClient.logEvent({
                    EventName: "LandingPageClick",
                    ComponentName: "HeaderMicrosoftLogoImage",
                });
                history.push(landingPagePath);
            }
            return [2 /*return*/];
        });
    }); }, [history, props.disableHeaderActions, telemetryClient]);
    var headerContainerStyles = useMemo(function () { return ({
        root: {
            background: theme.palette.themePrimary,
            height: "4.8rem",
            padding: "1rem",
        },
    }); }, [theme]);
    var homeButtonClassName = mergeStyles({
        ".ms-Fabric--isFocusVisible &": {
            ":focus": {
                boxShadow: "none",
                outline: theme.palette.white + " 0.1rem solid",
            },
        },
    });
    var userMenuButtonClassName = mergeStyles({
        color: theme.palette.white,
        width: "4.8rem",
        height: "4.8rem",
        padding: "0 0.7rem",
        ":hover": {
            color: theme.palette.white,
        },
        ".ms-Fabric--isFocusVisible &": {
            ":focus::after": {
                outline: theme.palette.white + " 0.1rem solid",
            },
        },
    });
    return (_jsxs(Stack, __assign({ horizontal: true, styles: headerContainerStyles, verticalAlign: "center", as: "header" }, { children: [_jsx(Stack.Item, __assign({ grow: true }, { children: _jsx(Link, __assign({ onClick: onGoToLandingPageClick, className: homeButtonClassName }, { children: _jsx(Image, { alt: companyLogoAltDescription, styles: headerImageStyles, src: process.env.IMAGES_BASE_PATH + "/logo.png" }, void 0) }), void 0) }), void 0), !props.disableHeaderActions ? (_jsxs(_Fragment, { children: [_jsx(Stack.Item, { children: _jsx(HelpMenuContainer, {}, void 0) }, void 0), userAccount && (_jsxs(Stack.Item, { children: [_jsx(ActionButton, __assign({ className: userMenuButtonClassName, "aria-label": userMenuAriaLabel, id: userMenuActionId, onClick: toggleUserMenuCalloutCallback }, { children: _jsx(Persona, { size: PersonaSize.size32, initialsColor: currentTheme.palette.neutralTertiary, imageUrl: process.env.IMAGES_BASE_PATH + "/persona.svg", imageAlt: personaAlt }, void 0) }), void 0), _jsx(UserMenuContainer, { toggle: isUserMenuCalloutVisible, targetElementId: userMenuActionId, toggleIsUserMenuCalloutVisible: toggleUserMenuCalloutCallback }, void 0)] }, void 0))] }, void 0)) : null] }), void 0));
};
