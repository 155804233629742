var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { TranslationKeys, useI18n, useAuth, useTelemetryClient, } from "../../../core/services";
import { ErrorCode } from "../../../utilities";
import { ErrorPageRenderer } from "./error-page-renderer";
import { Stack } from "@fluentui/react";
import { HeaderContainer } from "../../common/header";
import { FooterContainer } from "../../common/footer";
var errorPageContainerStyles = {
    root: {
        minHeight: "100vh",
    },
};
/* Implements the Error Page container
 */
export var ErrorPageContainer = function (props) {
    var translate = useI18n().translate;
    var history = useHistory();
    var authService = useAuth().authService;
    var telemetryClient = useTelemetryClient().telemetryClient;
    useEffect(function () {
        telemetryClient.successScenario("ApplicationPageLoad", {
            Page: "ErrorPage",
            ErrorCode: props.errorCode || ErrorCode.Unknown,
            HttpResponseStatus: props.status,
        });
    }, [telemetryClient, props.errorCode, props.status]);
    var signOutAndSwitchCallback = useCallback(function () {
        telemetryClient.logEvent({
            EventName: "LogoutClick",
            Page: "ErrorPage",
            ComponentName: "ErrorPageContainer",
            ErrorCode: props.errorCode || ErrorCode.Unknown,
            HttpResponseStatus: props.status,
        });
        authService.logOut();
    }, [authService, props.errorCode, props.status, telemetryClient]);
    var onGoToLandingPageClick = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var landingPagePath;
        return __generator(this, function (_a) {
            landingPagePath = process.env.DEVELOPER_PREVIEW_PARAM === "true"
                ? "/?microsoft-onelti-developer=true"
                : "/";
            telemetryClient.logEvent({
                EventName: "LandingPageClick",
                Page: "ErrorPage",
                ComponentName: "ErrorPageContainer",
            });
            history.push(landingPagePath);
            return [2 /*return*/];
        });
    }); }, [history, telemetryClient]);
    var onErrorTsgLinkClick = useCallback(function () {
        telemetryClient.logEvent({
            EventName: "ErrorTsgLinkClick",
            Page: "ErrorPage",
            ErrorCode: props.errorCode || ErrorCode.Unknown,
            HttpResponseStatus: props.status,
        });
    }, [props.errorCode, props.status, telemetryClient]);
    //Default error message and action
    var errorMessage = translate(TranslationKeys.errorMessageAuthFailureGeneric);
    var action = signOutAndSwitchCallback;
    var actionButtonText = translate(TranslationKeys.signOutAndSwitchButton);
    switch (props.errorCode) {
        // Authentication Failed.
        case ErrorCode.AuthErrorInteractiveLoginFailed:
        case ErrorCode.AuthErrorSilentSSO:
        case ErrorCode.AuthErrorAcquireTokenSilently:
        case ErrorCode.AuthErrorInteractionRequired:
        case ErrorCode.TokenInvalid:
        case ErrorCode.InteractionRequired: {
            errorMessage = translate(TranslationKeys.errorMessageAuthenticationFailed);
            action = signOutAndSwitchCallback;
            actionButtonText = translate(TranslationKeys.signOutAndSwitchButton);
            break;
        }
        // User is not authorized.
        case ErrorCode.Unauthorized: {
            errorMessage = translate(TranslationKeys.errorMessageUserUnauthorized);
            action = signOutAndSwitchCallback;
            actionButtonText = translate(TranslationKeys.signOutAndSwitchButton);
            break;
        }
        // Tenant is not authorized.
        case ErrorCode.TenantNotAuthorizedError: {
            errorMessage = translate(TranslationKeys.errorMessageTenantAuthorizationFailed);
            action = signOutAndSwitchCallback;
            actionButtonText = translate(TranslationKeys.signOutAndSwitchButton);
            break;
        }
        // Any other errors
        default: {
            errorMessage = translate(TranslationKeys.errorMessageAuthFailureGeneric);
            action = onGoToLandingPageClick;
            actionButtonText = translate(TranslationKeys.landingPageButton);
            break;
        }
    }
    var errorImageProperties = {
        imagePath: process.env.IMAGES_BASE_PATH + "/locked_computer_with_person.svg",
        alt: translate(TranslationKeys.lockedComputerWithPersonAltDescription),
    };
    return (_jsxs(Stack, __assign({ styles: errorPageContainerStyles }, { children: [_jsx(Stack.Item, { children: _jsx(HeaderContainer, {}, void 0) }, void 0), _jsx(Stack.Item, __assign({ grow: true }, { children: _jsx(ErrorPageRenderer, { errorMessage: errorMessage, actionButtonText: actionButtonText, action: action, errorImageProperties: errorImageProperties, errorTroubleshootingLink: "https://docs.microsoft.com/microsoft-365/lti/manage-microsoft-one-lti?view=o365-worldwide#issues-with-signing-in-to-the-microsoft-lms-gateway", onErrorTsgLinkClick: onErrorTsgLinkClick }, void 0) }), void 0), _jsx(Stack.Item, { children: _jsx(FooterContainer, {}, void 0) }, void 0)] }), void 0));
};
