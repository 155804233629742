import { useI18n, useTelemetryClient } from "../../../core/services";
/**
 * Generates report a problem link
 * Docs: https://edusupport.microsoft.com/support/help
 * @returns URL to edu support portal
 */
export var useReportAProblem = function () {
    var currentLanguageCode = useI18n().currentLanguageCode;
    var telemetryClient = useTelemetryClient().telemetryClient;
    var sessionId = telemetryClient.getSessionId();
    var urlSearchParams = new URLSearchParams({
        product_id: "lti_apps",
        platform_id: "web",
        lang_code: currentLanguageCode,
        resource_id: sessionId,
        correlation_id: sessionId,
    });
    var url = "https://aka.ms/edusupport" + "?" + urlSearchParams.toString();
    return url;
};
