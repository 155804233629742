// LanguageCodes with translations
// Lang codes: https://setup.intlservices.microsoft.com/Home/ConfigSettings?TeamID=39982
// Language Names: https://eng.ms/docs/cloud-ai-platform/developer-services/one-engineering-system-1es/1es-docs/touchdown-build/reference/touchdown-build-supported-languages#list-of-cultures-supported-by-touchdown-build
export var SupportedLanguageCodes = [
    // We don't need a translation file for this, since default translations are in en-US.
    "en",
    "ar",
    "az",
    "bg",
    "ca-Es-VALENCIA",
    "ca",
    "cs",
    "cy",
    "da",
    "de",
    "el",
    "en-GB",
    "es-MX",
    "es",
    "et",
    "eu",
    "fi",
    "fr-CA",
    "fr",
    "gl",
    "gu",
    "he",
    "hi",
    "hr",
    "hu",
    "id",
    "is",
    "it",
    "ja",
    "ka",
    "kk",
    "ko",
    "lt",
    "lv",
    "mk",
    "nb-NO",
    "nl",
    "nn-NO",
    "pl",
    "pt-BR",
    "pt-PT",
    "ro",
    "ru",
    "sk",
    "sl",
    "sq",
    "sr-Latn-RS",
    "sv",
    "th",
    "tr",
    "uk",
    "vi",
    "zh-Hans",
    "zh-Hant",
    /**
     * Pseudo language codes.
     * Can be used for testing.
     * https://eng.ms/docs/cloud-ai-platform/developer-services/one-engineering-system-1es/1es-docs/touchdown-build/reference/touchdown-build-pseudo-localization
     */
    "qps-ploc",
    "qps-ploca",
    "qps-plocm",
];
/**
 * Certain regional languages need to be mapped explicitly.
 * ll-cc -> ll fallback does not work. e.g. en-in should fallback to en-GB instead of en(en-US).
 * References:
 * 1. https://dev.azure.com/GlobalCollaborationService/Global%20Collaboration%20Service%20Project/_workitems/edit/138277?src=WorkItemMention&src-action=artifact_link
 * 2. Teams Fallbacks
 *
 */
export var fallbackLanguageCodes = [
    // English language
    { code: "en-us", translationCode: "en" },
    { code: "en-au", translationCode: "en-GB" },
    { code: "en-bz", translationCode: "en" },
    { code: "en-ca", translationCode: "en-GB" },
    { code: "en-029", translationCode: "en" },
    { code: "en-in", translationCode: "en-GB" },
    { code: "en-ie", translationCode: "en-GB" },
    { code: "en-jm", translationCode: "en" },
    { code: "en-my", translationCode: "en-GB" },
    { code: "en-nz", translationCode: "en-GB" },
    { code: "en-ph", translationCode: "en" },
    { code: "en-sg", translationCode: "en-GB" },
    { code: "en-za", translationCode: "en-GB" },
    { code: "en-tt", translationCode: "en" },
    { code: "en-zw", translationCode: "en-GB" },
    // Spanish
    { code: "es-co", translationCode: "es" },
    { code: "es-uy", translationCode: "es" },
    { code: "es-ar", translationCode: "es-MX" },
    { code: "es-ve", translationCode: "es-MX" },
    { code: "es-bo", translationCode: "es-MX" },
    { code: "es-cl", translationCode: "es-MX" },
    { code: "es-cr", translationCode: "es-MX" },
    { code: "es-do", translationCode: "es-MX" },
    { code: "es-ec", translationCode: "es-MX" },
    { code: "es-sv", translationCode: "es-MX" },
    { code: "es-gt", translationCode: "es-MX" },
    { code: "es-hn", translationCode: "es-MX" },
    { code: "es-ni", translationCode: "es-MX" },
    { code: "es-py", translationCode: "es-MX" },
    { code: "es-pa", translationCode: "es-MX" },
    { code: "es-pe", translationCode: "es-MX" },
    { code: "es-pr", translationCode: "es-MX" },
    { code: "es-us", translationCode: "es-MX" },
    //Chinese
    { code: "zh-cn", translationCode: "zh-Hans" },
    { code: "zh-tw", translationCode: "zh-Hant" },
];
export var TranslationKeys;
(function (TranslationKeys) {
    TranslationKeys["test"] = "test";
    TranslationKeys["companyLogoAltDescription"] = "companyLogoAltDescription";
    TranslationKeys["landingPageImageAltDescription"] = "landingPageImageAltDescription";
    TranslationKeys["landingPageTitle"] = "landingPageTitle";
    TranslationKeys["goToRegistrationPortalButton"] = "goToRegistrationPortalButton";
    TranslationKeys["homePageWelcomeTitle"] = "homePageWelcomeTitle";
    TranslationKeys["homePageDescription"] = "homePageDescription";
    TranslationKeys["addCredentialButton"] = "addCredentialButton";
    TranslationKeys["credentialList"] = "credentialList";
    TranslationKeys["addCredentialPanelHeader"] = "addCredentialPanelHeader";
    TranslationKeys["editCredentialPanelHeader"] = "editCredentialPanelHeader";
    TranslationKeys["addAppCredentialPanelHeader"] = "addAppCredentialPanelHeader";
    TranslationKeys["nextButton"] = "nextButton";
    TranslationKeys["backButton"] = "backButton";
    TranslationKeys["saveButton"] = "saveButton";
    TranslationKeys["cancelButton"] = "cancelButton";
    TranslationKeys["signOutButton"] = "signOutButton";
    TranslationKeys["selectLtiAppTitle"] = "selectLtiAppTitle";
    TranslationKeys["credentialDetailsTitle"] = "credentialDetailsTitle";
    TranslationKeys["connectLtiCredsTitle"] = "connectLtiCredsTitle";
    TranslationKeys["microsoftLtiKeysTitle"] = "microsoftLtiKeysTitle";
    TranslationKeys["lmsRegistrationKeysTitle"] = "lmsRegistrationKeysTitle";
    TranslationKeys["appSpecificSettingsTitle"] = "appSpecificSettingsTitle";
    TranslationKeys["reviewConfigTitle"] = "reviewConfigTitle";
    TranslationKeys["oneDriveTitle"] = "oneDriveTitle";
    TranslationKeys["teamsClassesTitle"] = "teamsClassesTitle";
    TranslationKeys["teamsMeetingsTitle"] = "teamsMeetingsTitle";
    TranslationKeys["oneDriveDescription"] = "oneDriveDescription";
    TranslationKeys["teamsClassesDescription"] = "teamsClassesDescription";
    TranslationKeys["teamsMeetingsDescription"] = "teamsMeetingsDescription";
    TranslationKeys["oneLtiDescription"] = "oneLtiAppDescription";
    TranslationKeys["oneLtiStaticTitle"] = "oneLtiStaticTitle";
    TranslationKeys["oneLtiVariableGlobalTitle"] = "oneLtiVariableGlobalTitle";
    TranslationKeys["oneLtiVariableLocalTitle"] = "oneLtiVariableLocalTitle";
    TranslationKeys["reflectTitle"] = "reflectTitle";
    TranslationKeys["reflectDescription"] = "reflectDescription";
    TranslationKeys["OneNoteTitle"] = "OneNoteTitle";
    TranslationKeys["OneNoteDescription"] = "OneNoteDescription";
    TranslationKeys["teamsAssignmentsTitle"] = "teamsAssignmentsTitle";
    TranslationKeys["teamsAssignmentsDescription"] = "teamsAssignmentsDescription";
    TranslationKeys["readingCoachTitle"] = "readingCoachTitle";
    TranslationKeys["readingCoachDescription"] = "readingCoachDescription";
    TranslationKeys["ltiApplogoAlt"] = "ltiApplogoAlt";
    TranslationKeys["credentialDetailsDescription"] = "credentialDetailsDescription";
    TranslationKeys["registrationLabel"] = "registrationLabel";
    TranslationKeys["registrationDesc"] = "registrationDesc";
    TranslationKeys["registrationInputPlaceholder"] = "registrationInputPlaceholder";
    TranslationKeys["lmsPlatformLabel"] = "lmsPlatformLabel";
    TranslationKeys["lmsPlatformDesc"] = "lmsPlatformDesc";
    TranslationKeys["lmsPlatformInputPlaceholder"] = "lmsPlatformInputPlaceholder";
    TranslationKeys["lmsPlatformSelection"] = "lmsPlatformSelection";
    TranslationKeys["otherLms"] = "otherLms";
    TranslationKeys["approvedDomainLabel"] = "approvedDomainLabel";
    TranslationKeys["approvedDomainDesc"] = "approvedDomainDesc";
    TranslationKeys["approvedDomainPlaceholder"] = "approvedDomainPlaceholder";
    TranslationKeys["errorMessageEmptyField"] = "errorMessageEmptyField";
    TranslationKeys["errorMessageInvalidUrlField"] = "errorMessageInvalidUrlField";
    TranslationKeys["errorMessageInvalidLengthField"] = "errorMessageInvalidLengthField";
    TranslationKeys["microsoftLtiKeysDesc"] = "microsoftLtiKeysDesc";
    TranslationKeys["ltiOidcRedirectUriLabel"] = "ltiOidcRedirectUriLabel";
    TranslationKeys["ltiOidcRedirectUriDesc"] = "ltiOidcRedirectUriDesc";
    TranslationKeys["targetLinkUriLabel"] = "targetLinkUriLabel";
    TranslationKeys["targetLinkUriDesc"] = "targetLinkUriDesc";
    TranslationKeys["ltiOidcStartUriLabel"] = "ltiOidcStartUriLabel";
    TranslationKeys["ltiOidcStartUriDesc"] = "ltiOidcStartUriDesc";
    TranslationKeys["appJwksUriLabel"] = "appJwksUriLabel";
    TranslationKeys["appJwksUriDesc"] = "appJwksUriDesc";
    TranslationKeys["copiedTooltipMessage"] = "copiedTooltipMessage";
    TranslationKeys["lmsRegistrationKeysDesc"] = "lmsRegistrationKeysDesc";
    TranslationKeys["ltiPublicKeysetUriLabel"] = "ltiPublicKeysetUriLabel";
    TranslationKeys["ltiClientIdLabel"] = "ltiClientIdLabel";
    TranslationKeys["ltiIssuerLabel"] = "ltiIssuerLabel";
    TranslationKeys["ltiAuthorizeUriLabel"] = "ltiAuthorizeUriLabel";
    TranslationKeys["ltiAccessTokenUriLabel"] = "ltiAccessTokenUriLabel";
    TranslationKeys["ltiDeploymentIdLabel"] = "ltiDeploymentIdLabel";
    TranslationKeys["ltiPublicKeysetUriDesc"] = "ltiPublicKeysetUriDesc";
    TranslationKeys["ltiClientIdDesc"] = "ltiClientIdDesc";
    TranslationKeys["ltiIssuerDesc"] = "ltiIssuerDesc";
    TranslationKeys["ltiAuthorizeUriDesc"] = "ltiAuthorizeUriDesc";
    TranslationKeys["ltiAccessTokenUriDesc"] = "ltiAccessTokenUriDesc";
    TranslationKeys["ltiDeploymentIdDesc"] = "ltiDeploymentIdDesc";
    TranslationKeys["ltiPublicKeysetUriPlaceholder"] = "ltiPublicKeysetUriPlaceholder";
    TranslationKeys["ltiClientIdPlaceholder"] = "ltiClientIdPlaceholder";
    TranslationKeys["ltiIssuerPlaceholder"] = "ltiIssuerPlaceholder";
    TranslationKeys["ltiAuthorizeUriPlaceholder"] = "ltiAuthorizeUriPlaceholder";
    TranslationKeys["ltiAccessTokenUriPlaceholder"] = "ltiAccessTokenUriPlaceholder";
    TranslationKeys["ltiDeploymentIdPlaceholder"] = "ltiDeploymentIdPlaceholder";
    TranslationKeys["oneDriveAppSettingsDesc"] = "oneDriveAppSettingsDesc";
    TranslationKeys["instructorAccessOneDriveAccounts"] = "instructorAccessOneDriveAccounts";
    TranslationKeys["studentAccessOneDriveAccounts"] = "studentAccessOneDriveAccounts";
    TranslationKeys["embedWordPermission"] = "embedWordPermission";
    TranslationKeys["embedExcelPermission"] = "embedExcelPermission";
    TranslationKeys["embedPowerpointPermission"] = "embedPowerpointPermission";
    TranslationKeys["reviewConfigDesc"] = "reviewConfigDesc";
    TranslationKeys["reviewConfigDescForEditFlow"] = "reviewConfigDescForEditFlow";
    TranslationKeys["ltiAppStepTitle"] = "ltiAppStepTitle";
    TranslationKeys["errorMessageForStep"] = "errorMessageForStep";
    TranslationKeys["editDetailsLink"] = "editDetailsLink";
    TranslationKeys["editRegistrationKeysLink"] = "editRegistrationKeysLink";
    TranslationKeys["editAddtionalSettingsLink"] = "editAddtionalSettingsLink";
    TranslationKeys["yesLabel"] = "yesLabel";
    TranslationKeys["noLabel"] = "noLabel";
    TranslationKeys["ltiConfigCreateSuccessMessage"] = "ltiConfigCreateSuccessMessage";
    TranslationKeys["ltiConfigCreateErrorMessage"] = "ltiConfigCreateErrorMessage";
    TranslationKeys["ltiConfigDeleteSuccessMessage"] = "ltiConfigDeleteSuccessMessage";
    TranslationKeys["ltiConfigDeleteErrorMessage"] = "ltiConfigDeleteErrorMessage";
    TranslationKeys["ltiConfigEditSuccessMessage"] = "ltiConfigEditSuccessMessage";
    TranslationKeys["ltiConfigEditErrorMessage"] = "ltiConfigEditErrorMessage";
    TranslationKeys["landingPageSubTitle"] = "landingPageSubTitle";
    TranslationKeys["getStartedPrompt"] = "getStartedPrompt";
    TranslationKeys["landingPageOfficeDirectHeader"] = "landingPageOfficeDirectHeader";
    TranslationKeys["landingPageOfficeDirectDescription"] = "landingPageOfficeDirectDescription";
    TranslationKeys["homePageDescriptionLearnMore"] = "homePageDescriptionLearnMore";
    TranslationKeys["landingPageSecureHeader"] = "landingPageSecureHeader";
    TranslationKeys["landingPageSecureDescription"] = "landingPageSecureDescription";
    TranslationKeys["landingPageRichUxHeader"] = "landingPageRichUxHeader";
    TranslationKeys["landingPageRichUxDescription"] = "landingPageRichUxDescription";
    TranslationKeys["appSelectionFormDescription"] = "appSelectionFormDescription";
    TranslationKeys["appSelectionFormDescriptionLink"] = "appSelectionFormDescriptionLink";
    TranslationKeys["footerPrivacy"] = "footerPrivacy";
    TranslationKeys["footerTermsOfUse"] = "footerTermsOfUse";
    TranslationKeys["footerCopyright"] = "footerCopyright";
    TranslationKeys["accessibilityLabel"] = "accessibilityLabel";
    TranslationKeys["ltiConfigsHeaderRegistration"] = "ltiConfigsHeaderRegistration";
    TranslationKeys["ltiConfigsHeaderLtiApp"] = "ltiConfigsHeaderLtiApp";
    TranslationKeys["ltiConfigsHeaderLms"] = "ltiConfigsHeaderLms";
    TranslationKeys["ltiConfigsHeaderStatus"] = "ltiConfigsHeaderStatus";
    TranslationKeys["ltiConfigsHeaderApprovedDomain"] = "ltiConfigsHeaderApprovedDomain";
    TranslationKeys["ltiConfigsHeaderDateCreated"] = "ltiConfigsHeaderDateCreated";
    TranslationKeys["ltiConfigHeaderDateModified"] = "ltiConfigHeaderDateModified";
    TranslationKeys["ltiConfigsHeaderActions"] = "ltiConfigsHeaderActions";
    TranslationKeys["shortTextDate"] = "shortTextDate";
    TranslationKeys["ltiConfigActiveState"] = "ltiConfigActiveState";
    TranslationKeys["ltiConfigDraftState"] = "ltiConfigDraftState";
    TranslationKeys["ltiConfigArchivedState"] = "ltiConfigArchivedState";
    TranslationKeys["deleteDialogHeader"] = "deleteDialogHeader";
    TranslationKeys["deleteDialogContent"] = "deleteDialogContent";
    TranslationKeys["deleteDialogDeleteButton"] = "deleteDialogDeleteButton";
    TranslationKeys["ltiConfigListingEmptyTitle"] = "ltiConfigListingEmptyTitle";
    TranslationKeys["ltiConfigListingEmptyDesc"] = "ltiConfigListingEmptyDesc";
    //Authorization Error messages to be displayed on the UI
    TranslationKeys["errorMessageAuthenticationFailed"] = "errorMessageAuthenticationFailed";
    TranslationKeys["errorMessageTenantAuthorizationFailed"] = "errorMessageTenantAuthorizationFailed";
    TranslationKeys["errorMessageUserUnauthorized"] = "errorMessageUserUnauthorized";
    TranslationKeys["errorMessageAuthFailureGeneric"] = "errorMessageAuthFailureGeneric";
    TranslationKeys["signInButton"] = "signInButton";
    TranslationKeys["signOutAndSwitchButton"] = "signOutAndSwitchButton";
    TranslationKeys["landingPageButton"] = "landingPageButton";
    TranslationKeys["lockedComputerWithPersonAltDescription"] = "lockedComputerWithPersonAltDescription";
    TranslationKeys["myOfficeProfile"] = "myOfficeProfile";
    TranslationKeys["viewAccount"] = "viewAccount";
    TranslationKeys["genericApiLoadFaiureErrorMessage"] = "genericApiLoadFaiureErrorMessage";
    TranslationKeys["listingApiFailureErrorMessage"] = "listingApiFailureErrorMessage";
    TranslationKeys["genericErrorImageAltDescription"] = "genericErrorImageAltDescription";
    TranslationKeys["listingErrorImageAltDescription"] = "listingErrorImageAltDescription";
    TranslationKeys["ltiAppsLoadErrorImageAltDescription"] = "ltiAppsLoadErrorImageAltDescription";
    TranslationKeys["ltiKeysErrorImageAltDescription"] = "ltiKeysErrorImageAltDescription";
    TranslationKeys["ltiLaunchErrorImageAltDescription"] = "ltiLaunchErrorImageAltDescription";
    TranslationKeys["retryButton"] = "retryButton";
    TranslationKeys["helpButtonAriaLabel"] = "helpButtonAriaLabel";
    TranslationKeys["helpForAdmins"] = "helpForAdmins";
    TranslationKeys["helpForEducators"] = "helpForEducators";
    TranslationKeys["reportProblem"] = "reportProblem";
    TranslationKeys["launchErrorMessageGeneric"] = "launchErrorMessageGeneric";
    TranslationKeys["launchErrorDescGeneric"] = "launchErrorDescGeneric";
    TranslationKeys["launchErrorMessageCookieNotFound"] = "launchErrorMessageCookieNotFound";
    TranslationKeys["launchErrorDescCookieNotFound"] = "launchErrorDescCookieNotFound";
    TranslationKeys["launchErrorDescDetailsNotFound"] = "launchErrorDescDetailsNotFound";
    TranslationKeys["launchErrorDescRequestValidationFailure"] = "launchErrorDescRequestValidationFailure";
    TranslationKeys["copyButtonAriaLabel"] = "copyButtonAriaLabel";
    TranslationKeys["ltiConfigTableAriaLabel"] = "ltiConfigTableAriaLabel";
    TranslationKeys["viewButtonAriaLabel"] = "viewButtonAriaLabel";
    TranslationKeys["editButtonAriaLabel"] = "editButtonAriaLabel";
    TranslationKeys["deleteButtonAriaLabel"] = "deleteButtonAriaLabel";
    TranslationKeys["dismissMessageBarButtonAriaLabel"] = "dismissMessageBarButtonAriaLabel";
    TranslationKeys["userMenuButtonAriaLabel"] = "userMenuButtonAriaLabel";
    TranslationKeys["personaAlt"] = "personaAlt";
    TranslationKeys["tsgLink"] = "tsgLink";
    TranslationKeys["ltiJwtSecurityTokenValidationFailureErrorMessage"] = "ltiJwtSecurityTokenValidationFailureErrorMessage";
    TranslationKeys["ltiJwtSecurityTokenValidationFailureErrorDesc"] = "ltiJwtSecurityTokenValidationFailureErrorDesc";
    TranslationKeys["lmsJwksNotReachableOrNotFoundFailureErrorMessage"] = "lmsJwksNotReachableOrNotFoundFailureErrorMessage";
    TranslationKeys["lmsJwksNotReachableOrNotFoundFailureErrorDesc"] = "lmsJwksNotReachableOrNotFoundFailureErrorDesc";
    TranslationKeys["selectLTIAppFormPageTitle"] = "selectLTIAppFormPageTitle";
    TranslationKeys["ltiCredentialDetailsFormPageTitle"] = "ltiCredentialDetailsFormPageTitle";
    TranslationKeys["microsoftLTIAppKeysFormPageTitle"] = "microsoftLTIAppKeysFormPageTitle";
    TranslationKeys["lmsKeysFormPageTitle"] = "lmsKeysFormPageTitle";
    TranslationKeys["additionalFormPageTitle"] = "additionalFormPageTitle";
    TranslationKeys["reviewAndSaveFormPageTitle"] = "reviewAndSaveFormPageTitle";
    TranslationKeys["createLtiCredentialFlowOption"] = "createLtiCredentialFlowOption";
    TranslationKeys["editLtiCredentialFlowOption"] = "editLtiCredentialFlowOption";
    TranslationKeys["defaultPageTitle"] = "defaultPageTitle";
    TranslationKeys["viewLTICredentialPageTitle"] = "viewLTICredentialPageTitle";
    TranslationKeys["deleteLTICredentialPageTitle"] = "deleteLTICredentialPageTitle";
    TranslationKeys["documentTitleForLandingPage"] = "documentTitleForLandingPage";
})(TranslationKeys || (TranslationKeys = {}));
export var DateTimeFormats;
(function (DateTimeFormats) {
    // Renders date as a short text based on user locale. eg: October 9, 2021
    DateTimeFormats["SHORT_TEXT_DATE"] = "SHORT_TEXT_DATE";
})(DateTimeFormats || (DateTimeFormats = {}));
