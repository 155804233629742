var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from "react";
import { Stack, Persona, PersonaSize, useTheme, ActionButton, Callout, Link, Text, } from "@fluentui/react";
import { TranslationKeys, useAuth, useI18n, useTelemetryClient, } from "../../../core/services";
var userMenuCalloutStyles = {
    root: {
        width: 320,
        maxWidth: "90%",
        padding: "2rem 2.4rem",
    },
};
var nameStackItemStyles = {
    root: {
        marginBottom: "0.5rem",
    },
};
var userNameStackItemStyles = {
    root: {
        marginBottom: "0.2rem",
    },
};
var viewLinkStackItemStyles = {
    root: {
        marginBottom: "0.2rem",
    },
};
var myOfficeProfileLinkStackItemStyles = {
    root: {
        marginBottom: "0.2rem",
    },
};
var nameStyles = {
    root: {
        overflowWrap: "anywhere",
        fontWeight: "bold",
    },
};
var userNameStyles = {
    root: {
        overflowWrap: "anywhere",
    },
};
/**
 * Implements the user menu component.
 * This compenent has the logout option
 */
export var UserMenuContainer = function (props) {
    var currentTheme = useTheme();
    var authService = useAuth().authService;
    var userAccount = authService.getActiveAccount();
    var translate = useI18n().translate;
    var telemetryClient = useTelemetryClient().telemetryClient;
    var signOutButtonText = translate(TranslationKeys.signOutButton);
    var viewAccountLinkText = translate(TranslationKeys.viewAccount);
    var myOfficeProfileLinkText = translate(TranslationKeys.myOfficeProfile);
    var personaAlt = translate(TranslationKeys.personaAlt);
    var logoutCallback = useCallback(function () {
        telemetryClient.logEvent({
            EventName: "LogoutClick",
            ComponentName: "UserMenuContainer",
        });
        authService.logOut();
    }, [authService, telemetryClient]);
    var viewAccountLink = "https://myaccount.microsoft.com/?ref=MeControl&login_hint=" + (userAccount === null || userAccount === void 0 ? void 0 : userAccount.username) + "&tid=" + (userAccount === null || userAccount === void 0 ? void 0 : userAccount.tenantId);
    var officeProfileLink = "https://microsoft-my.sharepoint.com/person.aspx";
    return (_jsx(_Fragment, { children: props.toggle && (_jsx(Callout, __assign({ styles: userMenuCalloutStyles, gapSpace: 0, target: "#" + props.targetElementId, onDismiss: props.toggleIsUserMenuCalloutVisible, setInitialFocus: true, isBeakVisible: false }, { children: _jsxs(Stack, { children: [_jsx(Stack, __assign({ horizontal: true, horizontalAlign: "end", verticalAlign: "center" }, { children: _jsx(ActionButton, __assign({ onClick: logoutCallback }, { children: _jsx(Text, __assign({ variant: "mediumPlus" }, { children: signOutButtonText }), void 0) }), void 0) }), void 0), _jsxs(Stack, __assign({ horizontal: true }, { children: [_jsx(Stack.Item, { children: _jsx(Persona, { size: PersonaSize.size32, initialsColor: currentTheme.palette.neutralTertiary, imageUrl: process.env.IMAGES_BASE_PATH + "/persona.svg", imageAlt: personaAlt }, void 0) }, void 0), _jsx(Stack.Item, { children: _jsxs(Stack, { children: [_jsx(Stack.Item, __assign({ styles: nameStackItemStyles }, { children: _jsx(Text, __assign({ variant: "mediumPlus", styles: nameStyles }, { children: userAccount === null || userAccount === void 0 ? void 0 : userAccount.name }), void 0) }), void 0), _jsx(Stack.Item, __assign({ styles: userNameStackItemStyles }, { children: _jsx(Text, __assign({ variant: "medium", styles: userNameStyles }, { children: userAccount === null || userAccount === void 0 ? void 0 : userAccount.username }), void 0) }), void 0), _jsx(Stack.Item, __assign({ styles: viewLinkStackItemStyles }, { children: _jsx(Link, __assign({ href: viewAccountLink, target: "_blank", rel: "noreferrer noopener" }, { children: viewAccountLinkText }), void 0) }), void 0), _jsx(Stack.Item, __assign({ styles: myOfficeProfileLinkStackItemStyles }, { children: _jsx(Link, __assign({ href: officeProfileLink, target: "_blank", rel: "noreferrer noopener", onKeyDown: function (e) {
                                                    if (e.keyCode === 9) {
                                                        props.toggleIsUserMenuCalloutVisible();
                                                    }
                                                } }, { children: myOfficeProfileLinkText }), void 0) }), void 0)] }, void 0) }, void 0)] }), void 0)] }, void 0) }), void 0)) }, void 0));
};
