var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { useAuth, useTelemetryClient } from "../../../core/services";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { EventType, } from "@azure/msal-browser";
import { PageLoaderRenderer } from "../page-loader";
import ErrorPageContainer from "../error-pages";
import { httpClient, ApiName, HttpResponseStatus, } from "../../../core/services/http";
import { logger } from "../../../core/services/logger";
import { ErrorCode } from "../../../utilities";
import { TenantDataBoundaryService } from "../../../core/services/tenant-data-boundary";
/**
 * This component will be called before each protected route is called
 * @param children component
 * @param children params
 * @returns JSX.Element
 */
export var AuthorisedRoute = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    var _b = useState({
        errorCode: null,
        responseStatus: 0,
        isLoading: true,
    }), authorizationResult = _b[0], setAuthorizationResult = _b[1];
    var inProgress = useMsal().inProgress;
    var isUserAuthenticated = useIsAuthenticated();
    var authService = useAuth().authService;
    var telemetryClient = useTelemetryClient().telemetryClient;
    useEffect(function () {
        var authorizeUser = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, errCode, exception_1, errCode;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        telemetryClient.beginScenario("AuthorizeUser");
                        return [4 /*yield*/, httpClient.get({
                                endpoint: "/api/login",
                                apiName: ApiName.authorize,
                            })];
                    case 1:
                        response = _a.sent();
                        if (response.ok) {
                            logger.debug("User is authorized with response:" + JSON.stringify(response));
                            telemetryClient.successScenario("AuthorizeUser", {
                                EventName: "AuthorizeApiCallSuccess",
                                HttpResponseStatus: HttpResponseStatus.Ok,
                            });
                            setAuthorizationResult({
                                errorCode: null,
                                responseStatus: HttpResponseStatus.Ok,
                                isLoading: false,
                            });
                            if (process.env.IS_EUDB_TELEMETRY_ROUTING_ENABLED === "true" &&
                                response.data &&
                                response.data.tenantDataBoundary !== null) {
                                TenantDataBoundaryService.setTenantDataBoundary(response.data.tenantDataBoundary);
                            }
                        }
                        else {
                            errCode = response.data && response.data.ErrorCode
                                ? response.data.ErrorCode
                                : ErrorCode.Unknown;
                            logger.error("Error in Authorize api call with error in response: " + JSON.stringify(response));
                            telemetryClient.failScenario("AuthorizeUser", {
                                EventName: "AuthorizeApiCallFailure",
                                ErrorCode: errCode,
                                HttpResponseStatus: response.status,
                            });
                            setAuthorizationResult({
                                errorCode: errCode,
                                responseStatus: response.status,
                                isLoading: false,
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        exception_1 = _a.sent();
                        errCode = exception_1.code ? exception_1.code : ErrorCode.Unknown;
                        logger.error("Exception happened while calling authorize api:" + JSON.stringify(exception_1));
                        telemetryClient.failScenario("AuthorizeUser", {
                            EventName: "AuthorizeApiCallFailure",
                            Message: exception_1.message,
                            ErrorCode: errCode,
                        });
                        setAuthorizationResult({
                            errorCode: errCode,
                            responseStatus: HttpResponseStatus.InternalServerError,
                            isLoading: false,
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var authenticateUser = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, authService.login()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); };
        authService.getMsalInstance().addEventCallback(function (event) {
            var _a, _b;
            if ((event.eventType === EventType.SSO_SILENT_SUCCESS ||
                event.eventType === EventType.LOGIN_SUCCESS) &&
                event.payload) {
                var authenticationResult = event.payload;
                if (event.eventType === EventType.LOGIN_SUCCESS) {
                    telemetryClient.logEvent({
                        EventName: "InteractiveLoginSuccess",
                    });
                }
                authService
                    .getMsalInstance()
                    .setActiveAccount(authenticationResult.account);
            }
            if (event.eventType === EventType.LOGIN_FAILURE) {
                var eventError = event.error;
                telemetryClient.logEvent({
                    EventName: "InteractiveLoginFailure",
                    ComponentName: "AuthorisedRoute",
                    ErrorCode: ErrorCode.AuthErrorInteractiveLoginFailed,
                    MsalErrorCode: (_a = eventError) === null || _a === void 0 ? void 0 : _a.errorCode,
                    Message: ((_b = eventError) === null || _b === void 0 ? void 0 : _b.errorMessage) ||
                        "Interactive Login failure event",
                });
            }
            if (event.eventType === EventType.LOGOUT_SUCCESS) {
                telemetryClient.successScenario("MSALLogout", {
                    EventName: "LogoutSuccess",
                });
            }
        });
        //If user is not authenticated try authenticating the user
        if (inProgress === "none" && !isUserAuthenticated) {
            authenticateUser();
        }
        //If user is authenticated then check for authorization
        if (inProgress === "none" && isUserAuthenticated) {
            authorizeUser();
        }
    }, [telemetryClient, authService, inProgress, isUserAuthenticated]);
    // If all requests has been completed and the authorize response is success
    // then redirect, else show error screen
    if (inProgress === "none" &&
        isUserAuthenticated &&
        authorizationResult.isLoading !== true &&
        authorizationResult.responseStatus === HttpResponseStatus.Ok) {
        return _jsx(Route, __assign({}, rest, { render: function () { return children; } }), void 0);
    }
    else if (inProgress === "none" &&
        authorizationResult.isLoading !== true &&
        (authorizationResult.errorCode ||
            authorizationResult.responseStatus !== HttpResponseStatus.Ok)) {
        return (_jsx(ErrorPageContainer, { errorCode: authorizationResult.errorCode, status: authorizationResult.responseStatus }, void 0));
    }
    return _jsx(PageLoaderRenderer, {}, void 0);
};
