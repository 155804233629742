import { useEffect } from "react";
export var useTitle = function (title) {
    useEffect(function () {
        var oldTitle = document.title;
        title && (document.title = title);
        return function () {
            document.title = oldTitle;
        };
    }, [title]);
};
