var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { authService } from "../auth";
import { TenantDataBoundary, } from "./tenant-data-boundary.interface";
var _TenantDataBoundaryContext = React.createContext(undefined);
var TENANT_DATA_BOUNDARY_LOCALSTORAGE_PREFIX = "TenantDataBoundary--";
var TenantDataBoundaryContext = /** @class */ (function () {
    function TenantDataBoundaryContext() {
        TenantDataBoundaryContext.tenantDataBoundary = TenantDataBoundary.UNKNOWN;
    }
    TenantDataBoundaryContext.prototype.getTenantDataBoundary = function () {
        if (TenantDataBoundaryContext.tenantDataBoundary !==
            TenantDataBoundary.UNKNOWN) {
            return TenantDataBoundaryContext.tenantDataBoundary;
        }
        else {
            try {
                var activeAccount = authService !== null && authService !== undefined
                    ? authService.getActiveAccount()
                    : null;
                if (activeAccount) {
                    var dataBoundaryFromLocalStorage = window.localStorage.getItem("" + TENANT_DATA_BOUNDARY_LOCALSTORAGE_PREFIX + activeAccount.tenantId);
                    if (dataBoundaryFromLocalStorage !== null) {
                        return dataBoundaryFromLocalStorage;
                    }
                }
            }
            catch (_a) {
                return TenantDataBoundary.UNKNOWN;
            }
            return TenantDataBoundary.UNKNOWN;
        }
    };
    TenantDataBoundaryContext.prototype.setTenantDataBoundary = function (dataBoundary) {
        TenantDataBoundaryContext.tenantDataBoundary = dataBoundary;
        try {
            var activeAccount = authService.getActiveAccount();
            if (activeAccount !== null) {
                window.localStorage.setItem("" + TENANT_DATA_BOUNDARY_LOCALSTORAGE_PREFIX + (activeAccount === null || activeAccount === void 0 ? void 0 : activeAccount.tenantId), dataBoundary);
            }
        }
        catch (_a) {
            // unable to set in local storage
        }
    };
    return TenantDataBoundaryContext;
}());
export { TenantDataBoundaryContext };
export var TenantDataBoundaryService = new TenantDataBoundaryContext();
export var TenantDataBoundaryProvider = React.memo(function (props) {
    var contextValue = React.useMemo(function () { return TenantDataBoundaryService; }, [TenantDataBoundaryService]);
    return (_jsx(_TenantDataBoundaryContext.Provider, __assign({ value: contextValue }, { children: props.children }), void 0));
});
TenantDataBoundaryProvider.displayName = "TenantDataBoundaryProvider";
