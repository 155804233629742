var _a;
export var ServiceName;
(function (ServiceName) {
    ServiceName["oneltiBackend"] = "oneltiBackend";
})(ServiceName || (ServiceName = {}));
export var ApiName;
(function (ApiName) {
    ApiName["test"] = "test";
    ApiName["getLtiConfigs"] = "getLtiConfigs";
    ApiName["createLtiConfig"] = "createLtiConfig";
    ApiName["editLtiConfig"] = "editLtiConfig";
    ApiName["getAppLtiDetails"] = "getAppLtiDetails";
    ApiName["getAppMetaData"] = "getAppMetaData";
    ApiName["deleteLtiConfig"] = "deleteLtiConfig";
    ApiName["authorize"] = "authorize";
})(ApiName || (ApiName = {}));
export var apiScenarios = (_a = {},
    _a[ApiName.test] = "TestScenario",
    _a[ApiName.getLtiConfigs] = "HttpGetLtiConfigs",
    _a[ApiName.createLtiConfig] = "HttpCreateLtiConfig",
    _a[ApiName.editLtiConfig] = "HttpUpdateLtiConfig",
    _a[ApiName.getAppLtiDetails] = "HttpGetAppLtiDetails",
    _a[ApiName.getAppMetaData] = "HttpGetAppMetaData",
    _a[ApiName.authorize] = "HttpAuthorize",
    _a[ApiName.deleteLtiConfig] = "HttpDeleteLtiConfig",
    _a);
export var HttpResponseStatus;
(function (HttpResponseStatus) {
    HttpResponseStatus[HttpResponseStatus["Ok"] = 200] = "Ok";
    HttpResponseStatus[HttpResponseStatus["BadRequest"] = 400] = "BadRequest";
    HttpResponseStatus[HttpResponseStatus["Unauthorized"] = 401] = "Unauthorized";
    HttpResponseStatus[HttpResponseStatus["NotFound"] = 404] = "NotFound";
    HttpResponseStatus[HttpResponseStatus["TooManyRequests"] = 429] = "TooManyRequests";
    HttpResponseStatus[HttpResponseStatus["InternalServerError"] = 500] = "InternalServerError";
})(HttpResponseStatus || (HttpResponseStatus = {}));
