var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LogLevel } from "@azure/msal-browser";
import { logger } from "../logger";
export var loggerCallBackFn = function (level, message, containsPii) {
    if (containsPii) {
        return;
    }
    switch (level) {
        case LogLevel.Error:
            logger.error(message);
            return;
        case LogLevel.Info:
            logger.log(message);
            return;
        case LogLevel.Verbose:
            logger.debug(message);
            return;
        case LogLevel.Warning:
            logger.warn(message);
            return;
    }
};
export var msalConfig = {
    auth: {
        /**
         * This is the ONLY mandatory field
         * Id of the registered Application in AAD, client apps need to be registered with Azure Portal/App Registration(Azure AD),
         * so that the Microsoft identity platform can deliver tokens representing the access that the user has consented to.
         */
        clientId: process.env.AAD_CLIENT_ID || "",
        //Use a sign-up/sign-in user-flow as a default authority
        authority: process.env.AAD_AUTHORITY || "",
        /**
         * Applocation URL to which redirection happens once authenticated
         * Points to window.location.origin. This must be registered this URI on Azure Portal/App Registration
         * Set to registartion page
         */
        redirectUri: process.env.AAD_LOGIN_REDIRECTURI || "",
        /**
         * navigateToLoginRequestUrl: true will redirect you to the original page that made the request,
         * make it to false when the application have other authenticated entrypoints than the origin URL
         * https://stackoverflow.com/questions/58699409/when-using-msal-to-implement-aad-authentication-what-would-i-use-the-navigateto
         * https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications#configuration-options
         */
        navigateToLoginRequestUrl: true,
    },
    cache: {
        // This configures where your cache will be stored
        cacheLocation: "localStorage",
        // Set this to "true" if you are having issues on IE11 or Edge
        storeAuthStateInCookie: false,
    },
    system: {
        //TODO: add application logger
        loggerOptions: {
            loggerCallback: loggerCallBackFn,
        },
    },
};
/**
 * Get the scope based on the application, for now only OneLTI application scope is required
 * @param project_scope
 * @returns scope
 */
export var getScope = function (projectScope) {
    switch (projectScope) {
        case "ONELTI_SCOPE":
            return process.env["ONELTI_SCOPE"] || "";
        default:
            return process.env["ONELTI_SCOPE"] || "";
    }
};
/**
 * Add scopes here to fetch access token.
 * As the target audience of OneLTI client is OneLTI backend hence the scope is for OneLTI backend
 * For different audience different scopes are set
 * Microsoft identity platform can deliver tokens representing the access that the user has consented to for this scope
 * Scopes those are added here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export var loginRequest = {
    scopes: [getScope("ONELTI_SCOPE")],
};
//Add login info for silently authenticating
export var silentRequest = __assign(__assign({}, loginRequest), { account: {} });
export var logoutRequest = {
    /**
     * Applocation URL to which redirection happens once user is logged out
     * Set to landing page
     */
    postLogoutRedirectUri: process.env.AAD_LOGOUT_REDIRECTURI || "", // your_app_logout_redirect_uri
};
